import { ServiceStatus, ServiceStatuses } from '@/api/model';
import colors from 'vuetify/lib/util/colors';

export interface StatusMapping {
    colorName: string,
    color: string,
    icon: string,
    displayName: string
}

class StatusMap {
    Unknown = getMapValue('grey', 'mdi-help-rhombus', 'Unknown');
    Okay =  getMapValue('green', 'mdi-check-circle', 'Okay');
    Warning = getMapValue('amber', 'mdi-alert', 'Warning');
    NotOkay = getMapValue('red', 'mdi-alert-octagon', 'Not Okay');

    null = this.Unknown;
    undefined = this.Unknown;

    [ServiceStatus.Unknown] = this.Unknown;
    [ServiceStatus.Okay] = this.Okay;
    [ServiceStatus.Warning] = this.Warning;
    [ServiceStatus.NotOkay] = this.NotOkay;
}

export const statusMap = new StatusMap();

function getMapValue(colorName: string, icon: string, displayName: string): StatusMapping {
    return {
        colorName,
        color: (colors as any)[colorName].base,
        icon,
        displayName
    };
}

export default {
    getStatusMapping(status: ServiceStatus): StatusMapping {
        if (status == null)
            return statusMap.undefined;

        const mapping = statusMap[status];
        if (mapping)
            return mapping;

        throw new Error(`Unknown status ${status}`);
    },
    getStatusColorName(status: ServiceStatus): string {
        return this.getStatusMapping(status).colorName;
    },
    getStatusColor(status: ServiceStatus): string {
        return this.getStatusMapping(status).color;
    },
    getStatusIcon(status: ServiceStatus): string {
        return this.getStatusMapping(status).icon;
    },
    getStatusDisplayName(status: ServiceStatus): string {
        return this.getStatusMapping(status).displayName;
    },
    combineStatuses(statuses: ServiceStatus[]): ServiceStatuses {
        let st = 0;
        for (let status of statuses) {
            st |= 1 << status;
        }
        return st;
    }
};