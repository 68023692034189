import * as model from './model';

import group from './group';
import service from './service';
import monitor from './monitor';

export default {
    model,
    group,
    service,
    monitor
};