



import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'file-size',
    props: {
        fileSize: {
            type: Number,
            required: true
        },
        si: {
            type: Boolean,
            default: false
        },
        dp: {
            type: Number,
            default: 1
        }
    },
    computed: {
        humanFileSize(): any {
            const thresh = this.si ? 1000 : 1024;
            let size = this.fileSize;

            if (Math.abs(size) < thresh) {
                return size + ' B';
            }

            const units = this.si
                ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
                : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
            let u = -1;
            const r = 10**this.dp;

            do {
                size /= thresh;
                ++u;
            } while (Math.round(Math.abs(size) * r) / r >= thresh && u < units.length - 1);

            return size.toFixed(this.dp) + ' ' + units[u];
        }
    },
    methods: {
        numberFormat: function (num: number) {
            return new Intl.NumberFormat().format(num);
        }
    }
});

