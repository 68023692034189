import { Module } from 'vuex';
import { RawLocation } from 'vue-router';

export interface BreadcrumbsState {
    items: BreadcrumbItem[]
}

export interface BreadcrumbModule extends Module<BreadcrumbsState, any> {
}

export interface BreadcrumbItem {
    id?: string,
    text: string,
    activeClass?: string,
    append?: boolean,
    disabled?: boolean,
    exact?: boolean,
    exactActiveClass?: string,
    href?: string | object,
    link?: boolean,
    replace?: boolean,
    ripple?: boolean,
    tag?: string,
    target?: string,
    to?: RawLocation
}

const module: BreadcrumbModule = {
    namespaced:  true,
    state: {
        items: []
    },
    mutations: {
        set(state: BreadcrumbsState, items: BreadcrumbItem[]) {
            state.items = items;
        },
        push(state: BreadcrumbsState, breadcrumb: BreadcrumbItem) {
            state.items.push(breadcrumb);
        },
        pop(state: BreadcrumbsState) {
            state.items.pop();
        },
        replace(state: BreadcrumbsState, payload: {find:String, replace:BreadcrumbItem}) {
            const index = state.items.findIndex((breadcrumb: BreadcrumbItem) => {
                return breadcrumb.id === payload.find;
            });

            if (index) {
                state.items.splice(index, 1, payload.replace);
            }
        },
        empty(state: BreadcrumbsState) {
            state.items = [];
        }
    }
};

export default module;