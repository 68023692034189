var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"title-bar"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"headline px-2 py-1",class:_vm.getStatusColorName(_vm.latestStatus)},[_c('v-icon',{staticStyle:{"vertical-align":"baseline"}},[_vm._v(_vm._s(_vm.getStatusIcon(_vm.latestStatus)))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),(_vm.subtitle)?_c('span',{staticClass:"caption text-right d-block"},[_vm._v("running on "+_vm._s(_vm.subtitle))]):_vm._e()]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"ma-0",attrs:{"color":"blue","hide-details":""},on:{"change":_vm.resetAutoReloadTimer},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Auto reload "),(_vm.autoReload.enabled)?_c('time-counter',{attrs:{"timestamp":_vm.autoReload.timestamp}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.autoReload.enabled),callback:function ($$v) {_vm.$set(_vm.autoReload, "enabled", $$v)},expression:"autoReload.enabled"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({},on),[_vm._v(" Mark as ")])]}}])},[_c('v-list',_vm._l(([1, 2, 3, 0].filter(function (x) { return x != _vm.latestStatus; })),function(status){return _c('v-list-item',{key:status},[_c('v-list-item-title',{staticClass:"mark-as-menu-item",on:{"click":function($event){return _vm.setStatus(status)}}},[_vm._v(" "+_vm._s(_vm.getStatusDisplayName(status)))])],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-alert',{staticClass:"ma-0",attrs:{"value":_vm.error != null,"type":"error","dense":"","dismissible":""},on:{"input":function($event){_vm.error = null}}},[_vm._v(_vm._s(_vm.error))])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',_vm._b({},'v-col',_vm.tableSize,false),[_c('v-card',{attrs:{"tile":"","elevation":"4"}},[_c('div',{class:_vm.$vuetify.breakpoint.xsOnly ? 'mobile' : ''},[_c('v-data-table',{ref:"messagesTable",attrs:{"id":"messages-table","dense":"","items":_vm.messages,"item-key":"id","headers":_vm.headers,"footer-props":_vm.footerProps,"server-items-length":_vm.messageCount,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.tableLoading,"disable-sort":true,"expanded":_vm.expanded,"single-expand":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.fetchTableData],"update:page":[function($event){_vm.page=$event},_vm.fetchTableData],"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center",class:_vm.getStatusColorName(item.status),staticStyle:{"width":"100%","height":"100%"}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(_vm._s(_vm.getStatusIcon(item.status)))])],1)]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('time-counter',{attrs:{"timestamp":item.timestamp,"countUp":true}})]}},_vm._l((_vm.customHeaders),function(header){return {key:header.slotName,fn:function(ref){
var item = ref.item;
return [_c('dynamic',{key:header.value,attrs:{"header":header,"value":item[header.value]}})]}}}),{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.details)?_c('message-details',{attrs:{"details":item.details}}):_c('v-progress-linear',{attrs:{"indeterminate":""}})],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',{class:_vm.$vuetify.breakpoint.xsOnly ? 'message-filters-mobile' : '',attrs:{"id":"message-filters"}},[_c('td',[_c('v-btn-toggle',{attrs:{"id":"status-filter","dense":"","multiple":"","tile":"","borderless":""},model:{value:(_vm.selectedStatuses),callback:function ($$v) {_vm.selectedStatuses=$$v},expression:"selectedStatuses"}},_vm._l(([1,2,3,0]),function(status){return _c('v-btn',{key:status,attrs:{"x-small":"","width":"20px","min-width":"20px","value":status,"color":_vm.statusMap[status].color}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.statusMap[status].icon))])],1)}),1)],1),_c('td',{attrs:{"colspan":_vm.headers.length - 1}})])]},proxy:true}],null,true)})],1)])],1),_c('v-col',_vm._b({},'v-col',_vm.statusChartSize,false),[_c('v-card',{attrs:{"tile":"","elevation":"4"}},[(_vm.chartLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('div',{staticClass:"px-3 pt-2"},[_c('apexchart',{attrs:{"options":_vm.statusChartOptions,"series":_vm.statusChartData}})],1),_c('v-card-actions',[_c('div',{staticClass:"chart-footer v-data-footer__select"},[_vm._v(" Show last "),_c('v-select',{staticClass:"mx-2",attrs:{"items":_vm.statusChartDaysOptions,"dense":"","hide-details":""},model:{value:(_vm.statusChartDays),callback:function ($$v) {_vm.statusChartDays=$$v},expression:"statusChartDays"}}),_vm._v(" days ")],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }