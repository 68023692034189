




import Vue, { PropType } from 'vue';

import Duration, { TimeUnits, TimeSpan, DurationFormat } from './Duration.vue';

const bufferTime = 10;

export default Vue.extend({
    name: 'time-counter',
    components: { Duration },
    props: {
        timestamp: [Date, Number, String] as PropType<Date | number | string>,
        countUp: {
            type: Boolean,
            default: false
        },
        allowNegative: {
            type: Boolean,
            default: false
        },
        format: {
            type: [Object, String] as PropType<DurationFormat | string>,
            required: false
        },
        negativeFormat: {
            type: [Object, String] as PropType<DurationFormat | string>,
            required: false
        },
        unit: {
            type: Number as PropType<TimeUnits>,
            required: false
        },
        nowThreshold: {
            type: Number,
            default: 1000
        }
    },
    data: () => ({
        durationValue: undefined as TimeSpan | undefined,
        timerHandle: undefined as number | undefined
    }),
    computed: {
        durationOptions(): any {
            return {
                format: defaultFormat(this.format, this.countUp ? 'past' : 'future'),
                negativeFormat: defaultFormat(this.negativeFormat, this.countUp ? 'future' : 'past'),
                unit: this.unit,
                nowThreshold: this.nowThreshold
            };

            function defaultFormat(fmt: any, defaultFmt: string): string | DurationFormat {
                return fmt == null ? defaultFmt : fmt;
            }
        },
        formattedTimestamp(): string | null {
            if (this.parsedTimestamp == null) return null;
            return this.parsedTimestamp.toLocaleString();
        },
        parsedTimestamp(): Date | null {
            if (this.timestamp == null) return null;
            switch (typeof this.timestamp) {
                case 'string':
                case 'number':
                    return new Date(this.timestamp);
                default:
                    if (this.timestamp instanceof Date)
                        return this.timestamp;
                    throw new Error(`Unsupported timestamp ${this.timestamp}`);
            }
        }
    },
    created() {
        this.updateDuration();
    },
    beforeDestroy() {
        if (this.timerHandle) {
            window.clearTimeout(this.timerHandle);
            this.timerHandle = undefined;
        }
    },
    watch: {
        parsedTimestamp() {
            this.updateDuration();
        }
    },
    methods: {
        updateDuration() {
            if (!this.parsedTimestamp) {
                this.durationValue = undefined;
                window.clearTimeout(this.timerHandle);
                this.timerHandle = undefined;
            } else {
                const now = Date.now();

                let diffMs = now - this.parsedTimestamp.getTime();
                let diff = new TimeSpan(this.countUp ? diffMs : -diffMs);

                if (diff.totalMs < 0 && !this.allowNegative) {
                    diff = TimeSpan.Zero;
                }

                this.durationValue = diff;

                if (diff.totalMs <= 0 && !this.allowNegative) {
                    return;
                }

                let updateIn;
                if (diffMs < 0) {
                    updateIn = -(diffMs % diff.bestUnits) - bufferTime;
                } else {
                    updateIn = (diff.bestUnits - (diffMs % diff.bestUnits));
                }
                updateIn += bufferTime;

                if (updateIn < 0) updateIn = 1;

                this.timerHandle = window.setTimeout(() => this.updateDuration(), updateIn);
            }
        }
    }
});
