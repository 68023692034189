






































































































import Vue, { PropType } from "vue";

import mixins from "vue-typed-mixins";

import StatusHelpers from "@/mixins/StatusHelpers";
import { ServiceStatus } from "@/api/model";

interface LogEntry {
    id: string | number;
    message: string;
    severity: ServiceStatus;
    source: string;
    metadata: object;
    knownLogs: Array<KnownLog>;
}

interface KnownLog {
    id: number;
    notes: string;
    status: ServiceStatus;
    issue: Issue;
}

interface Issue {
    reference: string;
    notes: string;
    status: string;
    title: string;
    url: string;
}

export default mixins(StatusHelpers).extend({
    name: "LogEntry",
    props: {
        logs: {
            type: Array as PropType<Array<LogEntry>>,
            required: false
        }
    },
    data() {
        return {
            showMetadata: {},
        };
    }
});
