import axios from 'axios';
import { ProgressEventHandler, Service, ID } from './model';

export interface ServiceApi {
    get(id: ID, progress?: ProgressEventHandler): Promise<Service>;
}

const api: ServiceApi = {
    async get(id: ID, progress?: ProgressEventHandler) {
        const response = await axios.get(`/api/Service/${id}?t=${Date.now()}`, { onDownloadProgress: progress });
        return response.data;
    }
};

export default api;