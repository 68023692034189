













import Vue, { PropType } from "vue";

export default Vue.extend({
    name: "http-response",
    props: {
        body: String,
        headers: {
            type: Object as PropType<{
                [key: string]: string
            }>,
            required: false
        }
    },
    computed: {
        head(): string {
            if (!this.headers) return '';
            let head = [];
            for (const name in this.headers) {
                head.push(name + ": " + this.headers[name]);
            }
            return head.sort().join("\r\n");
        },
        contentType(): string | undefined {
            if (!this.headers) return undefined;

            let ct = this.headers["Content-Type"];
            if (!ct) return undefined;

            let mime = /[-\w.]+\/[-\w.+]+/.exec(ct);
            if (mime) {
                return mime[0];
            }

            return undefined;
        }
    }
});
