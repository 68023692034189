import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './vuetify';
import store from './store';

import VueApexCharts from 'vue-apexcharts';

import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import axios from 'axios';

// Get all components in the root of the components folder
const globalComponents = require.context('./components', false);
globalComponents.keys().forEach(fileName => {
    // Get component config
    const componentConfig = globalComponents(fileName);

    // Get name of component from file name
    const componentName = getComponentName(fileName);

    // Register component globally
    Vue.component(
        componentName,
        componentConfig.default || componentConfig
    );
});

// Get all module components
const moduleComponents = require.context('./components/modules', true);
moduleComponents.keys().forEach(fileName => {
    const componentConfig = moduleComponents(fileName);
    const componentName = getModuleComponentName(fileName);

    // Register component globally
    Vue.component(
        componentName,
        componentConfig.default || componentConfig
    );
});

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app');

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        let returnUrl = encodeURIComponent(window.location.pathname);
        window.location.href = `/Account/SignIn?dest=${returnUrl}`;
    }
    return Promise.reject(error);
});

function pascalCase(str: string): string {
    return upperFirst(camelCase(str));
}

function getComponentName(path: string): string {
    let result = /([^\\/]+?)(\.\w+)?$/.exec(path);
    if (!result)
        throw new Error("Path invalid");
    return pascalCase(result[1]);
}

function getModuleComponentName(path: string): string {
    let result = /([^\\/]+)[\\/]([^\\/]+?)(\.\w+)?$/.exec(path);
    if (!result)
        throw new Error("Path invalid");

    let moduleName = pascalCase(result[1]);
    let componentName = pascalCase(result[2]);

    if (componentName.startsWith(moduleName)) {
        return componentName;
    }

    return moduleName + componentName;
}