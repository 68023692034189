




















































import Vue, { PropType } from 'vue';
import { MessageDetails, DataHeader } from '@/api/model';
import { BreadcrumbItem } from '@/store/breadcrumbs';

import omit from 'lodash/omit';

export default Vue.extend({
    name: 'message-details',
    props: {
        details: Object as PropType<MessageDetails>
    },
    data: () => ({
        timestampTitle: 'When the event was detected on the remote machine.',
        loggedTitle: 'When the event was logged by the root service.',
        delayTitle: 'Assumes the time is in sync on all machines.',
        servicePathTitle: 'The path the event message took to get to the root service.'
    }),
    computed: {
        serviceTimestamp(): Date {
            return new Date(this.details.serviceTimestamp);
        },
        loggedTimestamp(): Date {
            return new Date(this.details.loggedTimestamp);
        },
        delay(): number {
            return this.loggedTimestamp.getTime() - this.serviceTimestamp.getTime();
        },
        serviceTimestampStr(): string{
            return this.serviceTimestamp.toLocaleString();
        },
        loggedTimestampStr(): string{
            return this.loggedTimestamp.toLocaleString();
        },
        headers(): DataHeader[] {
            if (!this.details.headers) return [];
            return this.details.headers.filter(header => {
                return !header.omitIfNull || this.specificDetails[header.value] != null;
            });
        },
        specificDetails(): any {
            return this.details.specificDetails || {};
        },
        servicePath(): BreadcrumbItem[] {
            return this.details.path.map(text => ({ text }));
        }
    }
});
