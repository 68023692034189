












import Vue from 'vue';
export default Vue.extend({
    computed: {
        adLoginUrl() {
            let returnUrl = encodeURIComponent(window.location.pathname);
            return `/Account/SignIn?dest=${returnUrl}`;
        }
    }
});
