





























































































import Vue from "vue";
import $ from "jquery";
import splash from "./views/Splash.vue";
import login from "./views/Login.vue";

enum State {
    Loading,
    LoggedOut,
    LoggedIn,
    Error
}

export default Vue.extend({
    name: "App",
    components: { splash, login },
    data: () => ({
        drawer: false,
        state: State.Loading,
        navLinks: [
            {
                to: "/",
                text: "Dashboard"
            }
        ],
        user: {
            displayName: "",
            email: ""
        }
    }),
    computed: {
        breadcrumbs() {
            return this.$store.state.breadcrumbs.items;
        },
        loggedIn: function() {
            return this.state === State.LoggedIn;
        },
        contentComponent: function() {
            switch (this.state) {
                case State.Loading:
                    return "splash";
                case State.LoggedOut:
                    return "login";
                case State.LoggedIn:
                    return "router-view";
                case State.Error:
                    // TODO Add error view of some kind
                    return "TODO";
                default:
                    throw new Error("Unexpected state " + this.state);
            }
        }
    },
    created: async function() {
        try {
            var result = await $.get("/Account/");
            this.user = result;
            this.state = State.LoggedIn;
        } catch (e) {
            if (e.status == 401) {
                this.state = State.LoggedOut;
            } else {
                this.state = State.Error;
                debugger; // eslint-disable-line
            }
        }
    },
    watch: {
        breadcrumbs() {
            setTimeout(() =>{
                let elm = this.$refs.breadcrumbScroller as Element;
                if(elm) elm.scrollLeft = elm.scrollWidth;
            }, 1);
        }
    },
    methods: {
        breadcrumbsScroll(event: Event) {
            if (event.target instanceof Element) {
                let scWidth = event.target.scrollWidth;
                let clWidth = event.target.clientWidth;
                let left = event.target.scrollLeft;
                let right = scWidth - (clWidth + left);

                if (left > 12) {
                    event.target.classList.add('ellipsis-left');
                } else {
                    event.target.classList.remove('ellipsis-left');
                }

                if (right > 12) {
                    event.target.classList.add('ellipsis-right');
                } else {
                    event.target.classList.remove('ellipsis-right');
                }
            }
        }
    }
});
