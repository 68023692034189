import axios from 'axios';
import { ProgressEventHandler, Group, ID } from './model';

export interface GroupApi {
    list(progress?: ProgressEventHandler): Promise<Group[]>;
    get(id: ID, progress?: ProgressEventHandler): Promise<Group>;
}

const api: GroupApi = {
    async list(progress?: ProgressEventHandler) {
        const response = await axios.get(`/api/Group/List?t=${Date.now()}`, { onDownloadProgress: progress });
        return response.data;
    },
    async get(id: ID, progress?: ProgressEventHandler) {
        const response = await axios.get(`/api/Group/${id}?t=${Date.now()}`, { onDownloadProgress: progress });
        return response.data;
    }
};

export default api;