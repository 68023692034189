import Vue from 'vue';

let hiddenPropName: string | undefined = undefined;

const doc: any = document;

if (typeof doc.hidden !== "undefined") {
    hiddenPropName = "hidden";
} else if (typeof doc.msHidden !== "undefined") {
    hiddenPropName = "msHidden";
} else if (typeof doc.webkitHidden !== "undefined") {
    hiddenPropName = "webkitHidden";
}

export default Vue.extend({
    methods: {
        isPageVisible() {
            return !hiddenPropName || !doc[hiddenPropName];
        }
    }
});