import { BreadcrumbItem } from '@/store/breadcrumbs';
import api from "@/api";
import { Group, Service, ProgressEventHandler } from '@/api/model';

export interface IDashboardData {
    title: string | null;
    items: any[];
    breadcrumbs: BreadcrumbItem[];
}

export interface IDashboardController {
    fetch(...args: any[]): Promise<IDashboardData>;
}

export abstract class DashboardController<T> implements IDashboardController {
    protected abstract doFetch(...args: any[]): Promise<T>;

    protected abstract getTitle(data: T): string | null;
    protected abstract getItems(data: T): any[];
    protected abstract getCrumbs(data: T): BreadcrumbItem[];

    async fetch(...args: any[]): Promise<IDashboardData> {
        let data = await this.doFetch(...args);

        return {
            title: this.getTitle(data),
            items: this.getItems(data),
            breadcrumbs: this.getCrumbs(data)
        };
    }
}

class DefaultController extends DashboardController<Group[]> {
    fetch(progress?: ProgressEventHandler): Promise<IDashboardData> {
        return super.fetch(progress);
    }

    protected doFetch(progress?: ProgressEventHandler): Promise<Group[]> {
        return api.group.list(progress);
    }

    protected getTitle(): string {
        return 'Clients';
    }

    protected getItems(groups: Group[]): any[] {
        return groups.map(m => ({
            ...m,
            to: {
                name: 'group',
                params: { groupId: m.id.toString() }
            }
        }));
    }

    protected getCrumbs(): BreadcrumbItem[] {
        return [{ text: "Dashboard", to: "/" }];
    }
}

class GroupController extends DashboardController<Group> {
    fetch(groupId: number, progress?: ProgressEventHandler): Promise<IDashboardData> {
        return super.fetch(groupId, progress);
    }

    protected doFetch(groupId: number, progress?: ProgressEventHandler): Promise<Group> {
        return api.group.get(groupId, progress);
    }

    protected getTitle(group: Group): string {
        return `${group.name} Servers`;
    }

    protected getItems(group: Group): any[] {
        if (!group.services) return [];
        return group.services.map(s => ({
            ...s,
            to: {
                name: 'service',
                params: { serviceId: s.id.toString() }
            }
        }));
    }

    protected getCrumbs(group: Group): BreadcrumbItem[] {
        return [
            { text: "Dashboard", to: "/" },
            {
                text: group.name,
                to: { name: 'group', params: { groupId: group.id.toString() } }
            }
        ];
    }
}

class ServiceController extends DashboardController<Service> {
    fetch(serviceId: number, progress?: ProgressEventHandler): Promise<IDashboardData> {
        return super.fetch(serviceId, progress);
    }

    protected doFetch(serviceId: number, progress?: ProgressEventHandler): Promise<Service> {
        return api.service.get(serviceId, progress);
    }

    protected getTitle(service: Service): string {
        return `${service.name} Monitors`;
    }

    protected getItems(service: Service): any[] {
        if (!service.monitors) return [];
        return service.monitors.map(m => ({
            ...m,
            timestamp: new Date(m.timestamp),
            to: {
                name: 'monitor',
                params: { monitorId: m.id.toString() }
            }
        }));
    }

    protected getCrumbs(service: Service): BreadcrumbItem[] {
        if (!service.group) throw new Error('Group missing');
        return [
            { text: "Dashboard", to: "/" },
            {
                text: service.group.name,
                to: { name: 'group', params: { groupId: service.group.id.toString() } }
            },
            {
                text: service.name,
                to: { name: 'service', params: { serviceId: service.id.toString() } }
            }
        ];
    }
}

export const Controllers = {
    Default: new DefaultController(),
    Group: new GroupController(),
    Service: new ServiceController()
};