




import Vue, { PropType } from 'vue';

export enum TimeUnits {
    now = 0,
    ms = 1,
    sec = 1000,
    min = sec * 60,
    hour = min * 60,
    day = hour * 24
}

export class TimeSpan {
    public static readonly Zero: TimeSpan = new TimeSpan(0);

    constructor(ms: number) {
        this.totalMs = Math.trunc(ms);
        this.totalSec = Math.trunc(ms / TimeUnits.sec);
        this.totalMin = Math.trunc(ms / TimeUnits.min);
        this.totalHours = Math.trunc(ms / TimeUnits.hour);
        this.totalDays = Math.trunc(ms / TimeUnits.day);

        /*eslint-disable indent*/
        this.bestUnits =
            this.totalDays ? TimeUnits.day
            : this.totalHours ? TimeUnits.hour
            : this.totalMin ? TimeUnits.min
            : this.totalSec ? TimeUnits.sec
            : this.totalMs ? TimeUnits.ms
            : TimeUnits.now;
        /*eslint-enable indent*/
    }

    totalMs: number;
    totalSec: number;
    totalMin: number;
    totalHours: number;
    totalDays: number;

    bestUnits: TimeUnits;
}

export interface DurationFormat {
    now?: string;
    ms: string;
    seconds?: string;
    minutes?: string;
    hours?: string;
    days?: string;
}

export const defaultPastFormat = {
    now: 'now',
    ms: '{#} ms ago',
    seconds: '{#} second{*:,s} ago',
    minutes: '{#} minute{*:,s} ago',
    hours: '{#} hour{*:,s} ago',
    days: '{#} day{*:,s} ago'
} as DurationFormat;
export const defaultFutureFormat = {
    now: 'now',
    ms: 'in {#} ms',
    seconds: 'in {#} second{*:,s}',
    minutes: 'in {#} minute{*:,s}',
    hours: 'in {#} hour{*:,s}',
    days: 'in {#} day{*:,s}'
} as DurationFormat;
export const basicFormat = {
    now: 'now',
    ms: '{#} ms',
    seconds: '{#} second{*:,s}',
    minutes: '{#} minute{*:,s}',
    hours: '{#} hour{*:,s}',
    days: '{#} day{*:,s}'
} as DurationFormat;

export default Vue.extend({
    name: 'duration',
    props: {
        value: {
            type: [Number, Object, Date] as PropType<number | TimeSpan | Date>,
            required: false
        },
        title: {
            type: String,
            required: false
        },
        format: {
            type: [Object, String] as PropType<DurationFormat | string>,
            default: () => basicFormat
        },
        negativeFormat: {
            type: [Object, String] as PropType<DurationFormat | string>,
            required: false
        },
        unit: {
            type: Number as PropType<TimeUnits>,
            required: false
        },
        nowThreshold: {
            type: Number,
            default: 1000
        }
    },
    computed: {
        durationString(): string {
            if (this.value == null) {
                return '';
            }

            let duration: TimeSpan;
            if (this.value instanceof TimeSpan)
                duration = this.value;
            else if(this.value instanceof Date)
                duration = new TimeSpan(Date.now() - this.value.getTime());
            else
                duration = new TimeSpan(this.value);

            let format: DurationFormat;
            let negate: boolean;

            if (duration.totalMs < 0 && this.negativeFormat) {
                format = getFormat(this.negativeFormat);
                negate = true;
            } else {
                format = getFormat(this.format);
                negate = false;
            }

            // HACK: For some reason the method doesnt exist on the type of "this",
            // but it does actually exist at runtime
            return this.formatDuration(duration, format, negate);

            function getFormat(fmt: DurationFormat | string) {
                if (typeof fmt === 'string') {
                    switch (fmt) {
                        case 'basic':
                            return basicFormat;
                        case 'past':
                            return defaultPastFormat;
                        case 'future':
                            return defaultFutureFormat;
                        default:
                            return {
                                ms: fmt
                            } as DurationFormat;
                    }
                }

                return fmt;
            }
        }
    },
    methods: {
        formatDuration(duration: TimeSpan, format: DurationFormat, negate: boolean): string {
            let unit = this.unit;
            if (unit == null) unit = duration.bestUnits;
            if (unit == null) unit = TimeUnits.ms;

            if (format.now && duration.totalMs <= this.nowThreshold) {
                return formatPart(duration.totalMs, format.now);
            }

            /* eslint-disable no-fallthrough */
            switch (unit) {
                case TimeUnits.day:
                    if (format.days)
                        return formatPart(duration.totalDays, format.days);
                case TimeUnits.hour:
                    if (format.hours)
                        return formatPart(duration.totalHours, format.hours);
                case TimeUnits.min:
                    if (format.minutes)
                        return formatPart(duration.totalMin, format.minutes);
                case TimeUnits.sec:
                    if (format.seconds)
                        return formatPart(duration.totalSec, format.seconds);
                case TimeUnits.ms:
                    return formatPart(duration.totalMs, format.ms);
                default:
                    throw new Error(`Unexpected units ${unit}`);
            }
            /* eslint-enable no-fallthrough */

            function formatPart(amount: number, format: string): string {
                return format.replace(/({{|}})|{([^}]*)}/g, 
                    (orig, escape, content) => {
                        if (escape) return escape.substring(0, 1);
                        if (content === '#') return amount.toString();


                        let match = /^(\*):([^,}]*?),([^}]*?)$/.exec(content);
                        if (match) {
                            let kind = match[1];
                            let a = match[2];
                            let b = match[3];

                            if (kind === '*') {
                                return amount === 1 ? a : b;
                            }
                        }

                        return orig;
                    }).trim();
            }
        }
    }
});
