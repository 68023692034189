import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Monitor from '@/views/Monitor.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/Client/:groupId',
        name: 'group',
        component: Dashboard,
        props: true
    },
    {
        path: '/Server/:serviceId',
        name: 'service',
        component: Dashboard,
        props: true
    },
    {
        path: '/Monitor/:monitorId',
        name: 'monitor',
        component: Monitor,
        props: true
    }
    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
