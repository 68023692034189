
























































import Vue, { PropType } from "vue";
import moment from "moment";

import status from "@/utils/status";

interface databaseBackup {
    databaseName: string,
    backupStartDate: Date,
    backupFinishDate: Date,
    backupType: number,
    backupSize: bigint,
    status: number,
    backupFrequency: any
}

export default Vue.extend({
    name: "sql-database-backup-list",
    props: {
        databaseBackups: {
            type: Array as PropType<Array<databaseBackup>>,
            required: false
        }
    },
    methods: {
        statusIcon: function (db: databaseBackup) {
            return status.getStatusIcon(db.status);
        },
        statusColor: function (db: databaseBackup) {
            return status.getStatusColorName(db.status);
        },
        backupTypeStr: function (type: number) {
            switch (String.fromCharCode(type)) {
                case 'D':
                case 'F':
                    return 'Full';
                case 'I':
                case 'G':
                    return 'Diff';
                case 'L':
                    return 'Log';
                case 'P':
                case 'Q':
                    return 'Partial';
                default:
                    return 'Unknown';
            }
        },
        infoString: function (db: databaseBackup) {
            var time = db.backupFrequency ? moment.duration(db.backupFrequency.totalSeconds || 0, "seconds").humanize() : null;
            switch (db.status) {
                case 1:
                    if (time) {
                        return `Backup was completed within the configured frequency of ${time}.`;
                    } else {
                        return `Database has no backup frequency configured in RSM.`;
                    }
                case 2:
                    return `Database is flagged as unknown in RSM.`;
                case 3:
                    return `Backup was not completed within the configured frequency of ${time}.`;
            }
        }
    }
});
