








import Vue from "vue";
export default Vue.extend({
    name: "message",
    props: {
        text: String,
    },
});
