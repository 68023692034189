











import Vue, { PropType } from 'vue';

// HACK vuetify components arent globally registered. Need a better way of doing this.
import { VAlert } from 'vuetify/lib';

import { DataHeader } from '@/api/model';

export default Vue.extend({
    name: 'dynamic',
    components: { VAlert },
    props: {
        header: Object as PropType<DataHeader>,
        value: { }
    },
    computed: {
        bindComponent(): any {
            if (this.header.componentSlot)
                return this.header.extraComponentProps || {};

            let props: any = {};

            if (this.header.extraComponentProps) {
                Object.assign(props, this.header.extraComponentProps);
            }

            if (this.header.componentProp)
                props[this.header.componentProp] = this.value;
            else if (this.value != null && typeof this.value === 'object')
                Object.assign(props, this.value);
            else
                props.value = this.value;

            return props;
        }
    }
});
