import Vue from 'vue';
import status, {statusMap} from "@/utils/status";
import { ServiceStatus } from '@/api/model';

export default Vue.extend({
    data: () => ({
        statusMap,
        ServiceStatus
    }),
    methods: {
        ...status
    }
});