



































import { PropType } from 'vue'; // eslint-disable-line no-unused-vars

import mixins from 'vue-typed-mixins';

import BreadcrumbsManager from "@/mixins/BreadcrumbManager";
import PageVisibility from "@/mixins/PageVisibility";
import StatusHelpers from "@/mixins/StatusHelpers";

import { Controllers } from './Dashboard';

import DashTile from '@/components/dashboard/DashTile.vue';
import api from '../api';
import { StatusData } from '../api/model';

export default mixins(BreadcrumbsManager, PageVisibility, StatusHelpers).extend({
    name: "dashboard",
    components: { DashTile },
    props: {
        groupId: [String, Number] as PropType<number | string | undefined>,
        serviceId: [String, Number] as PropType<number | string | undefined>
    },
    data: () => ({
        loading: true,
        loadingProgress: -1,
        items: [] as any[],
        itemsKind: null as (string | null),
        title: null as (string | null),
        autoReload: {
            enabled: true,
            timestamp: null as (Date | null),
            timeoutId: undefined as (number | undefined),
            frequency: 60 * 1000
        },

        error: null as (string | null)
    }),
    created() {
        this.fetchData();
    },
    beforeDestroy() {
        if (this.autoReload.timeoutId) {
            window.clearTimeout(this.autoReload.timeoutId);
        }
        this.autoReload.timeoutId = undefined;
        this.autoReload.timestamp = null;
    },
    watch: {
        '$route': 'fetchData'
    },
    computed: {
        tileKind() {
            if (this.groupId) {
                return 'server';
            } else if (this.serviceId) {
                return 'monitor';
            } else {
                return 'client';
            }
        },
        tileShouldHaveStatusChart() {
            return true;
        }
    },
    methods: {
        async fetchData() {
            this.loadingProgress = -1;
            this.loading = true;

            try {
                let fetchPromise = null;

                if (this.groupId) {
                    fetchPromise = Controllers.Group.fetch(Number(this.groupId), this.fetchProgress);
                } else if (this.serviceId) {
                    fetchPromise = Controllers.Service.fetch(Number(this.serviceId), this.fetchProgress);
                } else {
                    fetchPromise = Controllers.Default.fetch(this.fetchProgress);
                }

                let data = await fetchPromise;

                this.title = data.title;
                this.mergeItems(data.items);
                this.setBreadcrumbs(data.breadcrumbs);

                this.loadingProgress = 100;

                if (this.tileShouldHaveStatusChart) {
                    setTimeout(() => {
                        for (const tile of this.items) {
                            this.fetchChartData(tile);
                        }
                    }, 1);
                }
            } catch (e) {
                console.error(e); // eslint-disable-line no-console
                this.error = "An error occured while fetching page data. See console for more information.";
            }

            this.loading = false;

            this.resetAutoReloadTimer();
        },

        fetchProgress(progress: ProgressEvent) {
            if (progress.lengthComputable) {
                this.loadingProgress = (100 * progress.loaded) / progress.total;
            } else {
                this.loadingProgress = -1;
            }
        },

        mergeItems(newItems: any[]) {
            if (!this.items || this.itemsKind != this.tileKind) {
                this.items = newItems;
                this.itemsKind = this.tileKind;
                return;
            }

            let oldItems: any;
            oldItems = this.items.reduce((obj, item) => (obj[item.id] = item, obj), {} as any);

            let newArray: any[] = [];

            for (const newItem of newItems) {
                let oldItem = oldItems[newItem.id];
                let item;
                if (oldItem) {
                    for (const name in newItem) {
                        if (name === 'id') continue;
                        oldItem[name] = newItem[name];
                    }
                    item = oldItem;
                } else {
                    item = newItem;
                }
                newArray.push(item);
            }

            this.items = newArray;
        },

        async fetchChartData(item: any) {
            try {
                let data = await api.monitor.getStatus(this.tileKind, item.id, 7);

                for (let elm of data.data) {
                    elm.date = new Date(elm.date).toLocaleDateString(undefined, { day: "numeric", month: "short" });
                }

                this.$set(item, 'statusChartOptions', this.getStatusChartOptions(data));
                this.$set(item, 'statusChartData', this.getStatusChartData(data));
                this.$set(item, 'hasStatusChart', true);
            } catch (e) {
                console.error(e); // eslint-disable-line no-console
                this.error = "An error occured while fetching page data. See console for more information.";
            }
        },

        getStatusChartOptions(rawData: StatusData): any {
            let colors: string[] = [];
            let xaxis: string[] = [];

            if (rawData && rawData.data) {
                if (rawData.anyOkay)
                    colors.push(this.statusMap.Okay.color);
                if (rawData.anyWarning)
                    colors.push(this.statusMap.Warning.color);
                if (rawData.anyNotOkay)
                    colors.push(this.statusMap.NotOkay.color);
                if (rawData.anyUnknown)
                    colors.push(this.statusMap.undefined.color);

                xaxis = rawData.data.map((d: any) => d.date);
            }

            return {
                chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
                    background: this.$vuetify.theme.currentTheme.secondary,
                    toolbar: {
                        show: false
                    },
                    parentHeightOffset: 0
                },
                theme: {
                    mode: this.$vuetify.theme.dark ? 'dark' : 'light'
                },
                fill: {
                    opacity: 1
                },
                plotOptions: {
                    bar: {
                        columnWidth: '95%'
                    }
                },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: xaxis,
                    labels: {
                        show: false
                    }
                },
                yaxis: {
                    show: false
                },
                grid: {
                    show: false
                },
                colors: colors
            };
        },

        getStatusChartData(rawData: StatusData): any[] {
            if (!rawData ||
                !rawData.data) return [];

            const map = (name: string, prop: string) => ({
                name,
                data: rawData.data.map((d: any) => d[prop])
            });

            let data = [];
            if (rawData.anyOkay)
                data.push(map("Okay", "okay"));
            if (rawData.anyWarning)
                data.push(map("Warning", "warning"));
            if (rawData.anyNotOkay)
                data.push(map("Not Okay", "notOkay"));
            if (rawData.anyUnknown)
                data.push(map("Unknown", "unknown"));

            return data;
        },

        resetAutoReloadTimer() {
            if (this.autoReload.timeoutId) {
                window.clearTimeout(this.autoReload.timeoutId);
            }

            if (this.autoReload.enabled) {
                this.autoReload.timeoutId = window.setTimeout(
                    () => {
                        if (this.isPageVisible()) {
                            this.fetchData();
                        } else {
                            this.resetAutoReloadTimer();
                        }
                    },
                    this.autoReload.frequency
                );

                this.autoReload.timestamp = new Date(Date.now() + this.autoReload.frequency);
            } else {
                this.autoReload.timeoutId = undefined;
                this.autoReload.timestamp = null;
            }
        }
    }
});
