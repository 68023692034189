



























import Vue, { PropType } from 'vue';

import { ServiceStatus } from '@/api/model';
import { RawLocation } from 'vue-router';

import status from "@/utils/status";

export default Vue.extend({
    name: 'dash-tile',
    props: {
        status: Number as PropType<ServiceStatus>,
        name: String,
        to: Object as PropType<RawLocation>,
        subtitle: {
            type: String,
            required: false
        }
    },
    computed: {
        statusColor() {
            return status.getStatusColorName(this.status);
        },
        statusIcon() {
            return status.getStatusIcon(this.status);
        }
    }
});
