




import Vue from 'vue';

import hljs from 'highlight.js';

export default Vue.extend({
    name: "highlight",
    props: {
        code: String,
        lang: {
            type: String,
            required: false,
            validator: (value: any) => {
                return typeof value === 'string' && hljs.listLanguages().indexOf(value) >= 0;
            }
        },
        contentType: {
            type: String,
            required: false
        },
        tabSize: {
            type: Number,
            required: false,
            validator: (value: any) => {
                return typeof value === 'number' && Math.floor(value) > 0;
            }
        }
    },
    computed: {
        html(): string {
            if (!this.code) return '';
            let lang = this.language;
            let result = lang
                ? hljs.highlight(lang, this.code, true)
                : hljs.highlightAuto(this.code);
            return result.value;
        },
        language(): string {
            if (this.lang) return this.lang;

            switch (this.contentType) {
                case null:
                case undefined:
                    return "";

                case "text/css":
                    return "css";
                case "text/html":
                case "text/xml":
                case "application/xml":
                    return "xml";
                case "text/javascript":
                    return "javascript";

                case "text/plain":
                    return "plaintext";
                default:
                    if (this.contentType.startsWith("text/"))
                        return "plaintext";
                    return "";
            }
        }
    }
});
