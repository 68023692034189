import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import {statusMap} from "@/utils/status";

Vue.use(Vuetify);

const opts = {
    theme: {
        dark: true,
        options: {
            customProperties: true
        },
        themes: {
            dark: {
                error: statusMap.NotOkay.color,
                warning: statusMap.Warning.color,
                success: statusMap.Okay.color
            }
        }
    },
    icons: {
        iconfont: "mdi" as "mdi"
    }
};

export default new Vuetify(opts);
