









































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';

import mixins from 'vue-typed-mixins';

import BreadcrumbsManager from "@/mixins/BreadcrumbManager";
import PageVisibility from "@/mixins/PageVisibility";
import StatusHelpers from "@/mixins/StatusHelpers";

import MessageDetails from '@/components/monitor/MessageDetails.vue';

import api from "@/api";
import { Monitor, Message, ServiceStatus, ServiceStatuses, ProgressEventHandler, ID, DataHeader, StatusData } from '@/api/model';

export default mixins(BreadcrumbsManager, PageVisibility, StatusHelpers).extend({
    name: 'monitor',
    components: { MessageDetails },
    props: {
        monitorId: [String, Number] as PropType<number | string>
    },
    data: () => ({
        tableLoading: true,
        chartLoading: true,

        autoReload: {
            enabled: true,
            timestamp: null as (Date | null),
            timeoutId: undefined as (number | undefined),
            frequency: 60 * 1000
        },

        title: undefined as (string | undefined),
        subtitle: undefined as (string | undefined),
        latestStatus: undefined as (ServiceStatus | undefined),

        customHeaders: [] as DataHeader[],
        tableWidth: undefined as (number | undefined),
        messages: undefined as (Message[] | undefined),
        messageCount: 0,
        expanded: [] as Message[],
        itemsPerPage: 10,
        page: 1,
        footerProps: {
            itemsPerPageOptions: [10, 20, 30, 40, 50]
        },

        selectedStatuses: [ServiceStatus.Unknown, ServiceStatus.Okay, ServiceStatus.Warning, ServiceStatus.NotOkay],
        previousSelectedStatuses: -1,

        statusChartDays: 14,
        statusChartDaysOptions: [ 7, 14, 21, 28 ],
        statusChartRawData: {} as StatusData,

        error: null as (string | null)
    }),
    computed: {
        headers() {
            return [
                { text: "Status", value: "status", width: 80 },
                { text: "Timestamp", value: "timestamp", width: 140 },
                ...this.customHeaders
            ];
        },
        tableSize(): any {
            let wid = this.tableWidth ? Math.min(this.tableWidth, 12) : 0;

            const hl = this.headers.length;
            return {
                xl: wid || Math.max(hl - 1, 3),
                lg: wid || Math.max(hl, 3),
                md: wid || Math.ceil(hl * 1.5),
                sm: 12,
                cols: 12
            };
        },
        statusChartSize(): any {
            const hl = this.headers.length;
            let sizes = this.tableSize;

            return {
                xl: clampSize(12 - sizes.xl, 4, 3),
                lg: clampSize(12 - sizes.lg, 4, 3),
                md: clampSize(12 - sizes.md, 12, 6),
                sm: 12,
                cols: 12
            };

            function clampSize(value: number, max: number, min: number, minValue?: number) {
                if (value > max) return max;
                if (value < min) return minValue || max;
                return value;
            }
        },
        statusChartOptions(): any {
            let colors: string[] = [];
            let xaxis: string[] = [];

            if (this.statusChartRawData && this.statusChartRawData.data) {
                if (this.statusChartRawData.anyOkay)
                    colors.push(this.statusMap.Okay.color);
                if (this.statusChartRawData.anyWarning)
                    colors.push(this.statusMap.Warning.color);
                if (this.statusChartRawData.anyNotOkay)
                    colors.push(this.statusMap.NotOkay.color);
                if (this.statusChartRawData.anyUnknown)
                    colors.push(this.statusMap.undefined.color);

                xaxis = this.statusChartRawData.data.map((d: any) => d.date);
            }

            return {
                chart: {
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
                    background: this.$vuetify.theme.currentTheme.secondary
                },
                theme: {
                    mode: this.$vuetify.theme.dark ? 'dark' : 'light'
                },
                fill: {
                    opacity: 1
                },
                plotOptions: {
                    bar: {
                        columnWidth: '90%'
                    }
                },
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    categories: xaxis
                },
                yaxis: {
                    show: false,
                    tickAmount: 4
                },
                colors: colors
            };
        },
        statusChartData(): any[] {
            if (!this.statusChartRawData ||
                !this.statusChartRawData.data) return [];

            const map = (name: string, prop: string) => ({
                name,
                data: this.statusChartRawData.data.map((d: any) => d[prop])
            });

            let data = [];
            if (this.statusChartRawData.anyOkay)
                data.push(map("Okay", "okay"));
            if (this.statusChartRawData.anyWarning)
                data.push(map("Warning", "warning"));
            if (this.statusChartRawData.anyNotOkay)
                data.push(map("Not Okay", "notOkay"));
            if (this.statusChartRawData.anyUnknown)
                data.push(map("Unknown", "unknown"));

            return data;
        }
    },
    created() {
        this.fetchPageData();
    },
    beforeDestroy() {
        if (this.autoReload.timeoutId) {
            window.clearTimeout(this.autoReload.timeoutId);
        }
        this.autoReload.timeoutId = undefined;
        this.autoReload.timestamp = null;
    },
    watch: {
        '$route': 'fetchPageData',
        'statusChartDays': 'fetchChartData',
        'selectedStatuses': 'fetchTableData'
    },
    methods: {
        async setStatus(status: ServiceStatus) {
            const response = await api.monitor.setStatus(this.monitorId, status);
            if (response === 200)
                this.latestStatus = status;
        },
        
        expandRow(message: Message) {
            const ind = this.expanded.indexOf(message);
            if (ind >= 0) {
                this.expanded = [];
            } else {
                this.expanded = [message];

                if (!message.details) {
                    this.fetchDetails(message);
                }
            }
        },

        mergeMessages(newMessages: Message[]) {
            if (!this.messages) {
                this.messages = newMessages;
                return;
            }

            let oldMsgs: { [id: string]: Message };
            oldMsgs = this.messages.reduce((obj, msg) => (obj[msg.id] = msg, obj), {} as any);

            let newMsgs: Message[] = [];

            let expandedId = null;
            let toExpand = null;
            if (this.expanded.length > 0) {
                expandedId = this.expanded[0].id;
            }

            for (const newMsg of newMessages) {
                let oldMsg = oldMsgs[newMsg.id];
                let msg;
                if (oldMsg) {
                    for (const name in newMsg) {
                        if (name === 'id') continue;
                        (oldMsg as any)[name] = (newMsg as any)[name];
                    }
                    msg = oldMsg;
                } else {
                    msg = newMsg;
                }
                newMsgs.push(msg);
                if (msg.id == expandedId) {
                    toExpand = msg;
                }
            }

            this.messages = newMsgs;

            if (toExpand) {
                this.expanded = [toExpand];
                if (!toExpand.details) {
                    this.fetchDetails(toExpand);
                }
            } else {
                this.expanded = [];
            }
        },

        fetchPageData() {
            this.fetchTableData();
            this.fetchChartData();
        },

        async fetchTableData() {
            this.tableLoading = true;

            try {
                let statuses = this.combineStatuses(this.selectedStatuses);

                if (statuses != this.previousSelectedStatuses) {
                    this.page = 1;
                    this.previousSelectedStatuses = statuses;
                }

                let offset = (this.page - 1) * this.itemsPerPage;

                // If none of the statuses are selected, then just dont show any messages.
                if (statuses == ServiceStatuses.None) {
                    this.messages = [];
                    this.messageCount = 0;
                    this.tableLoading = false;
                    return;
                }

                let data = await api.monitor.get(Number(this.monitorId), offset, this.itemsPerPage, statuses);

                if (!data.messages) data.messages = [];
                if (!data.headers) data.headers = [];

                this.title = data.name;
                this.subtitle = data.runningOn;
                this.latestStatus = data.status;
                this.mergeMessages(data.messages);
                this.messageCount = data.messageCount || 0;
                this.tableWidth = data.tableWidth;

                // Only update headers if any were sent or there were any messages.
                // The server currently wont return any headers if no data is returned, so will just use whatever the last headers were
                if (data.headers.length > 0 || data.messages.length > 0) {
                    this.customHeaders = data.headers || ([] as DataHeader[]);
                    for (let header of this.customHeaders) {
                        header.slotName = 'item.' + header.value;
                    }
                }

                if (!data.group) throw new Error('Group missing');
                if (!data.service) throw new Error('Service missing');

                this.setBreadcrumbs([
                    { text: "Dashboard", to: "/" },
                    {
                        text: data.group.name,
                        to: { name: 'group', params: { groupId: data.group.id.toString() } }
                    },
                    {
                        text: data.service.name,
                        to: { name: 'service', params: { serviceId: data.service.id.toString() } }
                    },
                    {
                        text: data.name,
                        to: { name: 'monitor', params: { monitorId: data.id.toString() } }
                    }
                ]);
            } catch (e) {
                console.error(e); // eslint-disable-line no-console
                this.error = "An error occured while fetching page data. See console for more information.";
            }

            this.tableLoading = false;

            this.resetAutoReloadTimer();
        },

        async fetchDetails(message: Message) {
            try {
                let data = await api.monitor.getMessage(message.id);

                this.$set(message, 'details', data);
            } catch (e) {
                console.error(e); // eslint-disable-line no-console
                this.error = "An error occured while fetching page data. See console for more information.";
            }
        },

        async fetchChartData() {
            this.chartLoading = true;

            try {
                let data = await api.monitor.getStatus('monitor', this.monitorId, this.statusChartDays);

                this.statusChartRawData = data;

                for (let elm of this.statusChartRawData.data) {
                    elm.date = new Date(elm.date).toLocaleDateString(undefined, { day: "numeric", month: "short" });
                }
            } catch (e) {
                console.error(e); // eslint-disable-line no-console
                this.error = "An error occured while fetching page data. See console for more information.";
            }
            this.chartLoading = false;
        },

        resetAutoReloadTimer() {
            if (this.autoReload.timeoutId) {
                window.clearTimeout(this.autoReload.timeoutId);
            }

            if (this.autoReload.enabled) {
                this.autoReload.timeoutId = window.setTimeout(
                    () => {
                        if (this.isPageVisible()) {
                            this.fetchPageData();
                        } else {
                            this.resetAutoReloadTimer();
                        }
                    },
                    this.autoReload.frequency
                );

                this.autoReload.timestamp = new Date(Date.now() + this.autoReload.frequency);
            } else {
                this.autoReload.timeoutId = undefined;
                this.autoReload.timestamp = null;
            }
        }
    }
});
